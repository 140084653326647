import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (localStorage.getItem('basic-token') != null) {
      // Add basic authentication headers
      const authHeaders = new HttpHeaders({
        'Authorization': 'Basic ' + localStorage.getItem('basic-token')
      });
      const modifiedRequest = request.clone({ headers: authHeaders });
      return next.handle(modifiedRequest);
    } else {
      return next.handle(request);
    }
  }
}
